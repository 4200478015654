.logo {
  text-align: center;
  position: fixed;
  top: 0;
  right: 0;
  width: 100%;
  background: $white;
  h1 {
    color: $black;
  }
}
