.logo {
  text-align: center;
  position: fixed;
  top: 0;
  right: 0;
  width: 100%;
  background: transparent;
  h1 {
    color: $white;
    font-size: 5rem;
  }
}
